import "./index.scss";

import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import PageLoader from "./components/layout/PageLoader";
import reportWebVitals from "./reportWebVitals";
import TagManager from "react-gtm-module";
import "./i18n";
import * as serviceWorker from "./serviceWorker";

const App = lazy(() => import("./App"));

if (process.env.NODE_ENV == "production") {
  const tagManagerArgs = {
    gtmId: "GTM-M2FQ8LC",
  };

  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<PageLoader />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
