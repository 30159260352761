import "./Logo.scss";

import PropTypes from "prop-types";
import classNames from "classnames";
import { ReactComponent as HotelbankLogo } from "./logo.svg";

const Logo = ({ inverted }) => {
  return (
    <HotelbankLogo
      width="350"
      height="60"
      className={classNames("hotelbank-logo", { inverted: inverted })}
    />
  );
};

Logo.defaultProps = {
  inverted: false,
};

Logo.propTypes = {
  inverted: PropTypes.bool,
};

export default Logo;
