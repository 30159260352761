import "./PageLoader.scss";

import Logo from "components/layout/Logo";

const PageLoader = () => {
  return (
    <div className="full-page-loader">
      <Logo />
    </div>
  );
};

export default PageLoader;
