import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

// Go to /en if the user accesses the root page
i18n.on("languageChanged", (language) => {
  if (language === i18n.options.fallbackLng[0]) {
    if (window.location.pathname === "/") {
      window.location.replace("/" + i18n.options.fallbackLng[0]);
    }
  }
});

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // debug: true,
    whitelist: ["en"],
    fallbackLng: "en",

    detection: {
      order: ["path"],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
